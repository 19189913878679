import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Loading from 'components/common/Loading'
import { withAuth } from 'components/common/Auth'
import { getLocationParam } from '~/utils/misc'
import { storeReturnUrl } from '~/utils/auth'

export default withAuth(({ isLoggedIn, login }) => {
  useEffect(() => {
    const next = getLocationParam(location, 'returnUrl') || '/'
    if (!isLoggedIn) {
      const nonce = storeReturnUrl(next)
      login({ nonce })
    } else {
      navigate(next)
    }
  }, [isLoggedIn])
  return (
    <Loading isFullScreen />
  )
})